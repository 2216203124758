<template>
	<div>
		<template v-if="categoryList.length > 0">
			<div class="room-setting">
				<div class="cate-list">
					<div class="table-operation">
						<a-space>
							<a-button
								type="primary"
								size="large"
								@click="
									categoryTitle = '添加分类'
									categoryVisible = true
									categoryForm = {
										title: '',
										type_id: defaultRoomType.id
									}
								">
								添加分类
							</a-button>
							<a-button
								type="primary"
								size="large"
								@click="
									categoryTitle = '桌型设置'
									roomTypeModalShow = true
								">
								桌型设置
							</a-button>
						</a-space>
					</div>
					<a-table
						id="table"
						:data-source="categoryList"
						:columns="columns"
						:customRow="tableCustomRow"
						:rowClassName="
							record =>
								record.id === selectedRow ? 'selected-row' : ''
						"
						rowKey="id"
						:showHeader="false"
						:pagination="false">
						<div
							class="flex justify-end"
							slot="action"
							slot-scope="record">
							<a
								class="select-none mr-5"
								@click.stop="categoryEdit(record)">
								编辑
							</a>
							<a
								class="select-none"
								@click.stop="categoryDel(record)"
								>删除</a
							>
						</div>
					</a-table>
				</div>
				<div class="room-list">
					<template v-if="roomList.length > 0">
						<div class="table-operation">
							<a-button
								type="primary"
								size="large"
								@click="roomAdd()">
								添加餐位
							</a-button>
						</div>
						<a-row :gutter="[25, 25]" id="room-list">
							<a-col
								:xxl="{ span: 6 }"
								:xl="{ span: 8 }"
								:lg="{ span: 12 }"
								:md="{ span: 12 }"
								v-for="item in roomList"
								:key="item.id"
								class="room-move-item">
								<div class="room">
									<div class="room-main">
										<div class="room-name">
											{{ item.title }}
										</div>
										<div class="room-number">
											容客人数：{{ item.number }}人
										</div>
									</div>
									<div class="room-footer">
										<button
											class="flex justify-center items-center flex-1"
											@click="roomEdit(item)">
											<a-icon type="edit" class="mr-1" />
											编辑
										</button>
										<a-divider type="vertical" />
										<button
											class="flex justify-center items-center flex-1"
											@click="roomDel(item)">
											<a-icon
												type="delete"
												class="mr-1" />
											删除
										</button>
									</div>
								</div>
							</a-col>
						</a-row>
					</template>
					<template v-else>
						<div class="room-empty">
							<img
								class="cate-empty-img"
								src="@/assets/dashboard-empty.svg"
								alt="" />
							<a-button
								size="large"
								type="primary"
								icon="plus"
								@click="roomAdd()">
								添加餐位
							</a-button>
						</div>
					</template>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="cate-empty">
				<img
					class="cate-empty-img"
					src="@/assets/dashboard-empty.svg"
					alt="" />
				<a-button
					size="large"
					type="primary"
					icon="plus"
					@click="
						categoryTitle = '添加分类'
						categoryVisible = true
					">
					添加分类
				</a-button>
			</div>
		</template>
		<!--分类名称弹窗-->
		<a-modal
			width="600px"
			:visible.sync="categoryVisible"
			:title="categoryTitle"
			@cancel="
				$refs.category.clearValidate()
				categoryVisible = false
				categoryForm = {}
			"
			@ok="categoryConfirm">
			<a-form-model
				ref="category"
				:model="categoryForm"
				:label-col="{ span: 5 }"
				:wrapper-col="{ span: 18 }">
				<a-form-model-item
					prop="title"
					label="分类名称"
					:rules="{
						required: true,
						message: '请输入分类名称(不超过5个字符)',
						max: 5
					}">
					<a-input
						v-model="categoryForm.title"
						placeholder="请输入分类名称" />
				</a-form-model-item>
				<a-form-model-item prop="title" label="桌型选择">
					<a-select
						v-model="categoryForm.type_id"
						defaultActiveFirstOption
						:options="roomTypeOptionsData" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!--添加/修改桌型弹窗-->
		<a-modal
			width="700px"
			:visible.sync="roomTypeAddModalShow"
			:title="categoryTitle"
			@cancel="
				roomTypeAddModalShow = false
				roomTypeForm = { name: '', id: '', images: [] }
			"
			@ok="roomTypeAddConfirm">
			<a-spin :spinning="isSpinning">
				<a-form-model
					ref="roomType"
					:model="roomTypeForm"
					:label-col="{ span: 5 }"
					:wrapper-col="{ span: 18 }">
					<a-form-model-item prop="id" style="display: none">
						<a-input v-model="roomTypeForm.id" type="hidden" />
					</a-form-model-item>
					<a-form-model-item
						prop="name"
						label="桌型名称"
						:rules="{
							required: true,
							message: '请输入桌型名称(不超过5个字符)',
							max: 5
						}">
						<a-input
							v-model="roomTypeForm.name"
							placeholder="请输入桌型名称" />
					</a-form-model-item>
					<a-form-model-item prop="picture" label="桌型展示">
						<img-uploader
							v-model="roomTypeForm.images"
							model-type="Arr"
							:max="5"
							:showUploadList="true"
							:multiple="true" />
						<div class="flex text-sm">
							<div class="text-theme-red mr-2">注:</div>
							<div>
								<p>1、格式必须为jpg/png</p>
								<p>2、建议尺寸：宽460px，高300px</p>
							</div>
						</div>
					</a-form-model-item>
				</a-form-model>
			</a-spin>
		</a-modal>
		<!--桌型列表弹窗-->
		<a-modal
			width="600px"
			:visible.sync="roomTypeModalShow"
			:footer="null"
			title="桌型设置"
			@cancel="roomTypeModalShow = false">
			<a-button
				type="primary"
				@click="roomTypeAddModalShow = true"
				style="margin-bottom: 10px"
				>添加桌型</a-button
			>
			<a-table
				:data-source="roomTypeData"
				:pagination="false"
				rowKey="id">
				<a-table-column key="name" title="名称" data-index="name" />
				<a-table-column key="action" title="操作">
					<template slot-scope="text, record">
						<span>
							<a @click="roomTypeModalConfirm(record)">编辑 </a>
							<a
								@click="roomTypeDelete(record)"
								v-if="record.default == 0">
								删除</a
							>
						</span>
					</template>
				</a-table-column>
			</a-table>
		</a-modal>
		<!--餐位弹窗-->
		<a-modal
			width="700px"
			:visible.sync="roomVisible"
			:title="roomTitle"
			@cancel="
				$refs.roomForm.clearValidate()
				roomVisible = false
				roomForm = {
					vr_url: {
						url: '',
						picture: ''
					},
					show: 0
				}
			"
			@ok="roomConfirm">
			<a-form-model
				ref="roomForm"
				:model="roomForm"
				:label-col="{ span: 5 }"
				:wrapper-col="{ span: 17 }"
				:rules="roomRules">
				<!-- :rules="{
					required: true,
					message: '请输入餐位名称',
					max: 9,
				}" -->
				<a-form-model-item prop="title" label="餐位名称">
					<a-input
						v-model="roomForm.title"
						placeholder="请输入餐位名称" />
				</a-form-model-item>
				<a-form-model-item
					prop="number"
					label="容客人数"
					:rules="{
						required: true,
						message: '请输入容客人数',
						type: 'number',
						min: 1
					}">
					<a-input
						v-model.number="roomForm.number"
						class="w-full"
						type="number"
						placeholder="请输入容客人数" />
				</a-form-model-item>
				<a-form-model-item
					v-if="roomTitle === '编辑餐位'"
					prop="number"
					label="移动分类"
					:rules="{
						required: true,
						message: '请选择移动分类'
					}">
					<a-select
						v-model="roomForm.category_id"
						placeholder="请选择移动分类">
						<a-select-option
							v-for="item in categoryList"
							:key="item.id"
							:value="item.id">
							{{ item.title }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item prop="show" label="展示类型">
					<a-radio-group
						v-model="roomForm.show"
						@change="validatorType">
						<a-radio :value="0">不对外展示</a-radio>
						<a-radio :value="1">图片展示</a-radio>
						<a-radio :value="2">VR展示</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<template v-if="roomForm.show === 1">
					<a-form-model-item
						prop="picture"
						label="餐位展示"
						:rules="{
							required: roomForm.show === 1,
							message: '请选择餐位展示图片'
						}">
						<img-uploader
							v-model="roomForm.picture"
							:max="9"
							model-type="Arr"
							:showUploadList="true"
							:multiple="true" />
						<!-- <template #help> -->
						<div class="flex text-sm">
							<div class="text-theme-red mr-2">注:</div>
							<div>
								<p>1、格式必须为jpg/png</p>
								<p>2、建议尺寸：宽460px，高300px</p>
							</div>
						</div>
						<!-- </template> -->
					</a-form-model-item>
				</template>
				<template v-if="roomForm.show === 2">
					<a-form-model-item
						label="VR链接"
						prop="vr_url.url"
						:rules="{
							required: roomForm.show === 2,
							message: '请输入VR链接'
						}">
						<a-input
							v-model="roomForm.vr_url.url"
							class="w-full"
							placeholder="请输入VR链接" />
					</a-form-model-item>
					<a-form-model-item
						label="VR图片"
						ref="vr_url"
						prop="vr_url.picture"
						:rules="{
							required: roomForm.show === 2,
							message: '请选择VP图片'
						}">
						<img-uploader
							v-model="roomForm.vr_url.picture"
							@success="$refs.vr_url.onFieldBlur()"
							@remove="$refs.vr_url.onFieldBlur()" />
					</a-form-model-item>
				</template>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import {
	delRoom,
	delRoomCategory,
	getRoom,
	getRoomCategoryList,
	roomType,
	saveRoom,
	saveRoomCategory,
	sortRoom,
	sortRoomCategory
} from '@/api/roomSetting'
import _ from 'lodash'
import Sortable from 'sortablejs'
import ImgUploader from '@/components/imgUploader'
import { message } from 'ant-design-vue'

export default {
	name: 'RoomSetting',
	components: { ImgUploader },
	data() {
		return {
			columns: [
				{
					dataIndex: 'title',
					ellipsis: true
				},
				{
					key: 'action',
					scopedSlots: { customRender: 'action' }
				}
			],
			isSpinning: false,
			roomId: undefined,
			selectedRow: '',
			categoryList: [],
			roomList: [],
			categoryForm: {
				title: '',
				type_id: 0
			},

			categoryVisible: false,
			roomTypeModalShow: false,
			roomTypeAddModalShow: false,
			categoryTitle: '添加分类',
			roomVisible: false,
			roomTitle: '添加餐位',
			roomForm: {
				title: '',
				number: '',
				picture: [],
				vr_url: {
					url: '',
					picture: ''
				},
				show: 0
			},
			roomRules: {
				title: [
					{
						required: true,
						message: '请输入餐位名称',
						trigger: 'blur'
					},
					{
						min: 1,
						max: 9,
						message: '字数不可超过9个字符',
						trigger: 'blur'
					}
				]
				// "vr_url.url": [
				//   { required: true, message: "请输入VR链接地址", trigger: "blur" },
				// ],
				// "vr_url.picture": [
				//   { required: true, message: "请上传图片", trigger: "blur" },
				// ],
				// vr_url: {
				//   trigger: ["change", "blur"],
				//   validator: (rule, value, callback) => {
				//     if (value.url && !value.picture) {
				//       callback(new Error("请上传VR缩略图"));
				//     } else {
				//       callback();
				//     }
				//   },
				// },
			},
			tableCustomRow: record => ({
				on: {
					click: () => {
						this.selectedRow = record.id
					}
				}
			}),
			//桌型相关变量
			roomTypeForm: {
				id: '',
				name: '',
				images: []
			},
			roomTypeData: [],
			roomTypeOptionsData: [],
			//默认桌型
			defaultRoomType: {}
		}
	},
	async mounted() {
		await this.getRoomCategoryList()
		this.selectedRow = this.categoryList[0].id
		//  拖拽事件一定要放在 mounted 生命周期内，因为这时真实的DOM才渲染出来
		this.initCateSortable()
		//加载桌型列表
		this.getRoomTypeList()
	},
	methods: {
		async getRoomCategoryList() {
			await this.$store.dispatch('loading/startContentLoading')
			await getRoomCategoryList()
				.then(res => {
					if (res.code === 0) {
						this.categoryList = res.data
					}
					this.$store.dispatch('loading/endContentLoading')
				})
				.catch(() => this.$store.dispatch('loading/endContentLoading'))
		},
		categoryEdit(record) {
			this.categoryForm = _.cloneDeep(record)
			this.categoryTitle = '编辑分类'
			this.categoryVisible = true
		},
		categoryDel({ id }) {
			const h = this.$createElement
			this.$confirm({
				width: '600px',
				title: '删除分类',
				content: '确定要删除该分类和分类下的内容吗？',
				icon: () =>
					h('a-icon', {
						props: { type: 'info-circle' },
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({ content: '加载中', key: 'delCate' })
					delRoomCategory({ id }).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delCate'
							})
							this.getRoomCategoryList()
						}
					})
				}
			})
		},
		categoryConfirm() {
			this.$refs.category
				.validate()
				.then(() => {
					this.$message.loading({
						content: '保存中',
						key: 'category'
					})
					saveRoomCategory(this.categoryForm).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '保存成功',
								key: 'category'
							})
							this.selectedRow = res.data.id
							this.categoryVisible = false
							this.categoryForm = {}
							this.getRoomCategoryList()
						}
					})
				})
				.catch(err => err)
		},
		//添加桌型
		roomTypeAddConfirm() {
			this.isSpinning = true;

			// 格式化默认桌型展示图片字段
			if (this.roomTypeForm.images === null) {
				this.roomTypeForm.images = new Array()
			};
			
			roomType
				.add(this.roomTypeForm)
				.then(res => {
					this.isSpinning = false
					if (res.code === 0) {
						message.success('添加成功')
						this.getRoomTypeList()
						this.roomTypeAddModalShow = false
						this.roomTypeForm = { name: '', id: '', picture: [] }
					}
				})
				.catch(err => {
					this.isSpinning = false
				})
		},
		//桌型列表
		getRoomTypeList() {
			roomType.getList().then(res => {
				this.roomTypeData = res.data
				this.roomTypeOptionsData = []
				res.data.map(item => {
					this.roomTypeOptionsData.push({
						value: item.id,
						label: item.name
					})
					if (item.default === 1) {
						this.defaultRoomType = item
					}
				})
			})
		},
		//桌型编辑
		roomTypeModalConfirm(record) {
			this.roomTypeForm = _.cloneDeep(record)
			this.roomTypeAddModalShow = true
		},
		//删除桌型
		roomTypeDelete({ id }) {
			const h = this.$createElement
			this.$confirm({
				width: '600px',
				title: '删除桌型',
				content: '确定要删除桌型吗？',
				icon: () =>
					h('a-icon', {
						props: { type: 'info-circle' },
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({ content: '加载中', key: 'delCate' })
					roomType.delete({ id }).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delCate'
							})
							this.getRoomTypeList()
						}
					})
				}
			})
		},
		roomEdit(record) {
			this.roomId = record.id
			this.roomForm = _.cloneDeep(record)
			this.roomTitle = '编辑餐位'
			this.roomVisible = true
		},
		roomAdd() {
			this.roomId = undefined
			this.roomTitle = '添加餐位'
			this.roomVisible = true
		},
		roomDel({ id }) {
			this.$confirm({
				width: '600px',
				title: '删除餐位',
				content: '确定要删除该餐位吗？',
				icon: () =>
					this.$createElement('a-icon', {
						props: { type: 'info-circle' },
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({ content: '加载中', key: 'delRoom' })
					delRoom({ id }).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delRoom'
							})
							this.getRoomList()
						}
					})
				}
			})
		},
		/**
		 * 提交-餐位添加
		 */
		roomConfirm() {
			this.$refs.roomForm
				.validate()
				.then(() => {
					const type = this.roomForm.show / 1
					if (type === 2) {
						if (!this.roomForm.vr_url.url) {
							this.$message.error('请输入VR链接')
							return
						}
						if (!this.roomForm.vr_url.picture) {
							this.$message.error('请上传VR图片')
							return
						}
					}
					this.$message.loading({
						content: '保存中',
						key: 'category'
					})
					const data = {
						id: this.roomId,
						category_id:
							this.roomForm.category_id || this.selectedRow,
						title: this.roomForm.title,
						number: this.roomForm.number,
						show: this.roomForm.show,
						picture: type === 1 ? this.roomForm.picture : [],
						vr_url: {
							url: type === 2 ? this.roomForm.vr_url.url : '',
							picture:
								type === 2 ? this.roomForm.vr_url.picture : ''
						}
					}
					saveRoom(data).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '保存成功',
								key: 'category'
							})
							this.roomVisible = false
							this.roomForm = {
								vr_url: {
									url: '',
									picture: ''
								},
								show: 0
							}
							this.getRoomList()
						}
					})
				})
				.catch(err => err)
		},
		getRoomList() {
			this.$store.dispatch('loading/startContentLoading')
			getRoom({ category_id: this.selectedRow })
				.then(res => {
					if (res.code === 0) {
						const list = res.data || []
						list.length &&
							list.forEach(item => {
								if (!item.vr_url) {
									item.vr_url = { picture: '', url: '' }
								}
							})
						this.roomList = list
						if (list.length > 0)
							this.$nextTick(() => this.initRoomSort())
					}
					this.$store.dispatch('loading/endContentLoading')
				})
				.catch(() => this.$store.dispatch('loading/endContentLoading'))
		},
		/**
		 * 分类排序
		 * oldIndex:谁
		 * newIndex:移动到哪儿
		 */
		initCateSortable() {
			const table = document.querySelector('#table tbody')
			// eslint-disable-next-line no-unused-vars
			const sortableObj = new Sortable(table, {
				handle: '.ant-table-row',
				animation: 150,
				sort: true,
				onEnd: ({ newIndex, oldIndex }) => {
					if (newIndex === oldIndex) return
					const currRow = this.categoryList.splice(oldIndex, 1)[0]
					this.categoryList.splice(newIndex, 0, currRow)
					this.sortCategory()
				}
			})
		},
		/**
		 * 餐位排序
		 * oldIndex:谁
		 * newIndex:移动到哪儿
		 */
		initRoomSort() {
			const list = document.querySelector('#room-list')
			// eslint-disable-next-line no-unused-vars
			const sortableObj = new Sortable(list, {
				handle: '.room-move-item',
				animation: 150,
				sort: true,
				onEnd: ({ newIndex, oldIndex }) => {
					if (newIndex === oldIndex) return
					const currRow = this.roomList.splice(oldIndex, 1)[0]
					this.roomList.splice(newIndex, 0, currRow)
					this.sortRoom()
				}
			})
		},
		sortCategory() {
			sortRoomCategory({
				category: this.categoryList.map((item, index) => ({
					id: item.id,
					sort: index
				}))
			}).then(res => {})
		},
		sortRoom() {
			sortRoom({
				room: this.roomList.map((item, index) => ({
					id: item.id,
					sort: index
				}))
			}).then(res => {})
		},
		validatorType() {
			//  this.props.roomForm.resetFields();
		}
	},
	watch: {
		selectedRow: {
			handler() {
				this.getRoomList()
			}
		}
	}
}
</script>

<style scoped>
.cate-empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ccenter;
	height: calc(100vh - 150px);
}

.cate-empty-img {
	width: 432px;
	height: 250px;
	margin-bottom: 7px;
}

.cate-list {
	background-color: white;
	margin-right: 6px;
	width: 344px;
	min-height: calc(100vh - 150px);
	border-radius: 4px;
	padding: 25px 22px;
}

.room-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.room-setting {
	display: flex;
	min-height: calc(100vh - 150px);
	background-color: #f0f2f5;
}

.room-list {
	flex: 1;
	height: 100%;
	background-color: white;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 25px 22px;
}

::v-deep .ant-table-row {
	cursor: pointer;
}

::v-deep .selected-row {
	background-color: #fff4f0;
}

.room {
	width: 100%;
	height: 174px;
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #e8e8e8;
}

.room:hover {
	box-shadow: 0 2px 8px 1px #f2f2f2;
}

.room-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 132px;
	border-bottom: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 12px;
}

.room-footer {
	width: 100%;
	background-color: white;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	height: 40px;
	background: #f9f9f9;
	border-radius: 0 0 2px 2px;
}

.room-number {
	font-size: 16px;
	color: #333333;
	text-align: center;
	line-height: 24px;
}

.room-name {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 6px;
}

.subscribe-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100% - 20px);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	padding: 20px;
}

.ant-modal-body {
	padding: 24px 24px 0;
}
</style>
